import { useState } from "react";
import { useCurrentClient } from "state/ducks";

import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import PreviewAreaHeader from "containers/admin/clients/client/theme-page/preview/preview-area/components/preview-area-header/preview-area-header";
import PreviewAreaContent from "containers/admin/clients/client/theme-page/preview/preview-area/components/preview-area-content/preview-area-content";

const PreviewArea = () => {
  // Current view
  const [currentView, setCurrentView] = useState("Mobile");
  const isMobileView = currentView === "Mobile";
  const maxWidthClass = isMobileView ? "max-w-[25rem]" : `max-w-full`;

  // Get client
  const client = useCurrentClient();

  return (
    <div className={`flex flex-col gap-3 pl-6 py-5 pr-6 ${maxWidthClass}`}>
      <ToggleButtonGroup
        groupName="preview-modes-toggle"
        options={["Desktop", "Mobile"]}
        onChange={(option) => {
          setCurrentView(option);
        }}
        value={currentView}
      />
      <span className="font-bold text-neutral-3 text-sm tracking-widest">PREVIEW</span>
      <div className="border border-neutral-4 overflow-x-auto">
        <PreviewAreaHeader clientName={client.name} isMobileView={isMobileView} />
        <PreviewAreaContent clientName={client.name} />
      </div>
    </div>
  );
};

export default PreviewArea;
