/**
 * These are default values from Tailwind and custom properties from our design system that can be used both in the Tailwind config file and Chakra UI components, as we transition fully to Tailwind.
 *
 * https://github.com/tailwindlabs/tailwindcss/blob/main/stubs/config.full.js
 */

function createTheme() {
  // Define base colors first

  const baseNeutralColors = {
    1: "#323B48",
    2: "#5B6470",
    3: "#858B93",
    4: "#E4E5E7",
    5: "#F5F5F5",
    offWhite: "#FCFCFC",
    white: "#FFFFFF",
  };

  const baseUtilityColors = {
    error: "#FF533D",
    info: "#2FC9FC",
    success: "#05B56C",
    warning: "#FFAB0F",
  };

  const aqua = "#0D736A";

  return {
    borderRadius: {
      none: "0px",
      sm: "0.125rem",
      DEFAULT: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
      full: "9999px",
    },
    boxShadow: {
      "01": `0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)`,
      sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      DEFAULT: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
      md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
      lg: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
      xl: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
      "2xl": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
      inner: "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)",
      none: "none",
      input: "0px 0px 0px 2px #c6f5ed",
      toast: `
      0px 3px 5px 0px rgba(0, 0, 0, 0.20), 
      0px 1px 18px 0px rgba(0, 0, 0, 0.12), 
      0px 6px 10px 0px rgba(0, 0, 0, 0.14)`,
    },
    colors: {
      neutral: baseNeutralColors,
      neutrals: baseNeutralColors,
      utility: baseUtilityColors,
      primary: {
        aqua: {
          50: "#F0FDFA",
          100: "#C6F5ED",
          200: "#84ECDC",
          300: "#20DFC5",
          400: "#1BC1AD",
          500: "#149F91",
          600: aqua,
          DEFAULT: aqua,
          dark: aqua,
          700: "#0C554D",
          800: "#093E37",
          900: "#08352E",
        },
        azure: {
          dark: "#0F6385",
          DEFAULT: "#3CB4E5",
        },
        navy: {
          DEFAULT: "#002E6D",
        },
        scarlett: {
          DEFAULT: "#FF595A",
          dark: "#C20000",
          "hovered-required-indicator": "#DC2626",
        },
        utility: {
          error: baseUtilityColors.error,
          info: {
            DEFAULT: baseUtilityColors.info,
            text: "#0E5592",
          },
          success: {
            DEFAULT: baseUtilityColors.success,
            text: "#01683D",
          },
          warning: {
            DEFAULT: baseUtilityColors.warning,
            text: "#B76D07",
          },
          wrong: {
            DEFAULT: baseUtilityColors.error,
            text: "#B71E25",
          },
        },
      },
    },
    fontSize: {
      xs: ["0.75rem", { lineHeight: "1rem" }],
      sm: ["0.875rem", { lineHeight: "1.25rem" }],
      base: ["1rem", { lineHeight: "1.5rem" }],
      lg: ["1.125rem", { lineHeight: "1.75rem" }],
      xl: ["1.25rem", { lineHeight: "1.75rem" }],
      "2xl": ["1.5rem", { lineHeight: "2rem" }],
      "3xl": ["1.875rem", { lineHeight: "2.25rem" }],
      "4xl": ["2.25rem", { lineHeight: "2.5rem" }],
      "5xl": ["3rem", { lineHeight: "1" }],
      "6xl": ["3.75rem", { lineHeight: "1" }],
      "7xl": ["4.5rem", { lineHeight: "1" }],
      "8xl": ["6rem", { lineHeight: "1" }],
      "9xl": ["8rem", { lineHeight: "1" }],
    },
    fontWeight: {
      thin: "100",
      extralight: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900",
    },
    spacing: {
      px: "1px",
      0: "0px",
      0.5: "0.125rem",
      1: "0.25rem",
      1.5: "0.375rem",
      2: "0.5rem",
      2.5: "0.625rem",
      3: "0.75rem",
      3.5: "0.875rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "2.75rem",
      12: "3rem",
      14: "3.5rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      28: "7rem",
      32: "8rem",
      36: "9rem",
      40: "10rem",
      44: "11rem",
      48: "12rem",
      52: "13rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      72: "18rem",
      80: "20rem",
      96: "24rem",
    },
  } as const;
}

const theme = createTheme();

// ESM export for Chakra UI
export default theme;

// CommonJS export for Tailwind
if (typeof module !== "undefined" && module.exports) {
  module.exports = theme;
}
