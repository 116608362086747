import { useState, useRef, useEffect, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { useThemePreviewContext } from "containers/admin/clients/client/theme-page/contexts/theme-preview-context";

import { PreviewSidebarInputType } from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

import Icons from "assets";

const HexInput = forwardRef<HTMLDivElement, PreviewSidebarInputType>(
  (
    {
      inputNameKey,
      value,
      label,
      isRequired,
      isDisabled = false,
      errMessage,
      handleCustomChange,
      handleBlurForNotRequiredField,
      ...rest
    },
    ref,
  ) => {
    const [isPickerVisible, setPickerVisible] = useState(false);
    const { isEditMode } = useThemePreviewContext();
    const colorInputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleFocus = () => {
      if (isEditMode) {
        setPickerVisible(true);
      }
    };

    useEffect(() => {
      if (!isEditMode) {
        setPickerVisible(false);
      }
    }, [isEditMode]);

    const handleBlur = (event: React.FocusEvent<HTMLDivElement>, field: any) => {
      const currentTarget = event.currentTarget;

      // Delay to allow focus to shift to another child input before checking if we should close
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          setPickerVisible(false);

          //in case field is not required
          if (!isRequired && field === "#") {
            setPickerVisible(false);

            !!handleBlurForNotRequiredField &&
              handleBlurForNotRequiredField(inputNameKey as string);
          }
        }
      }, 0);
    };

    // logic to handle color picker opening with only one click
    const handleSvgClick = () => {
      setPickerVisible(true);

      if (!colorInputRef.current) {
        setTimeout(() => {
          if (colorInputRef.current) {
            colorInputRef.current.click();
            colorInputRef.current.focus();
          }
        }, 0);
      }
    };

    // logic to handle color picker focus on tab
    const handleSvgFocus = () => {
      if (!colorInputRef.current) {
        setTimeout(() => {
          if (colorInputRef.current) {
            colorInputRef.current.focus();
          }
        }, 0);
      }
    };

    //styles
    const colorPickerWrapperStyles = twMerge(
      "flex flex-col gap-2 cursor-default",
      isEditMode && "cursor-pointer",
    );

    const hexInputStyles = twMerge(
      "flex gap-2.5 rounded-md border border-transparent bg-white px-2.5 py-1 text-base font-normal leading-6 h-10 items-center",
      isDisabled && "bg-zinc-200",
      isEditMode && !errMessage && "color-picker-input",
      !!errMessage && "border border-wrong-text",
    );

    const colorPickerInputStyles = twMerge(
      "bg-transparent rounded-[2px] h-6 w-7 cursor-default color-picker-button",
      isEditMode && "cursor-pointer",
    );

    const textInputStyles = twMerge(
      "text-base cursor-default focus:outline-none w-full",
      isDisabled && "bg-transparent",
    );

    const colorPickerButtonStyles = twMerge(
      "bg-transparent size-6 flex items-center justify-center cursor-default",
      isEditMode && "cursor-pointer",
    );

    const chooseColorIconStyles = twMerge(
      "w-[25px] h-7 text-neutrals-3",
      isEditMode && "text-neutrals-4",
    );

    return (
      <div
        role="button"
        className={colorPickerWrapperStyles}
        onFocus={handleFocus}
        onBlur={(e) => handleBlur(e, value)}
        ref={ref || wrapperRef}>
        <div className="flex flex-wrap">
          <label
            htmlFor={inputNameKey as string}
            id={`${inputNameKey}-label`}
            className="w-full text-sm font-semibold text-text-label">
            {label}

            {!isDisabled && isRequired ? (
              <span>
                <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
              </span>
            ) : null}
          </label>
        </div>

        <div className={hexInputStyles} tabIndex={-1}>
          {isPickerVisible || value ? (
            <input
              type="color"
              data-testid={`${inputNameKey}-color-hex-picker`}
              disabled={!isEditMode}
              className={colorPickerInputStyles}
              {...rest}
              ref={colorInputRef}
              onInput={(e) => {
                handleCustomChange((e.target as HTMLInputElement).value, true);
              }}
              value={value || ""}
            />
          ) : (
            <button
              type="button"
              data-testid={`${inputNameKey}-svg-icon`}
              className={colorPickerButtonStyles}
              disabled={!isEditMode}
              onMouseDown={handleSvgClick}
              onFocus={handleSvgFocus}>
              <Icons.ChooseColor className={chooseColorIconStyles} />
            </button>
          )}

          <input
            type="text"
            data-testid="color-text-input"
            aria-describedby={!!errMessage ? `${inputNameKey}-error` : undefined}
            className={textInputStyles}
            placeholder="Type hex code..."
            maxLength={7}
            {...rest}
            onFocus={() => {
              if (!value?.startsWith("#")) {
                const newValue = `#${value}`;

                handleCustomChange(newValue);
              }
            }}
            onKeyUp={(e) => {
              const target = e.target as HTMLInputElement;

              const value = !target.value.startsWith("#")
                ? `#${target.value.replace(/#/g, "")}`
                : target.value;

              handleCustomChange(value);
            }}
            value={value || ""}
          />
        </div>

        {!!errMessage && (
          <div className="flex" id={`${inputNameKey}-error`} role="alert">
            <Icons.InfoIndicator className="text-wrong-text size-5 flex items-center justify-center" />

            <span className="text-wrong-text ml-1 text-sm">
              <div>{errMessage}</div>
            </span>
          </div>
        )}
      </div>
    );
  },
);

export default HexInput;
