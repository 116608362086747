import CustomAccordion from "components/new/accordion/accordion";

import {
  PreviewSidebarItemsType,
  PreviewSidebarItemType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

const PreviewSidebar = ({
  sidebarItems,
  isAccordionApplied = false,
  sidebarInfoWarning,
}: PreviewSidebarItemsType) => {
  return (
    <div className="flex flex-col h-full w-[320px] border-r border-zinc-200 overflow-y-auto custom-scroll py-5 pr-6 pl-8 gap-5">
      {sidebarInfoWarning}
      {sidebarItems.map((el: PreviewSidebarItemType, ind: number) =>
        isAccordionApplied ? (
          <CustomAccordion
            variant="default"
            key={el.titleText + ind}
            title={el.titleText}
            isOpen={el.isOpen}>
            {el.children}
          </CustomAccordion>
        ) : (
          <div key={ind + el.titleText}>
            <span className="tracking-accordion-header-ls font-accordion-header text-neutral-3">
              {el.titleText}
            </span>
            <div className="mt-2">{el.children}</div>
          </div>
        ),
      )}
    </div>
  );
};

export default PreviewSidebar;
