import Icons from "assets";

export const getTabViews = (hasWarning: boolean) => {
  return [
    { label: "Preview", value: "Preview" },
    {
      label: (
        <div className=" flex items-end">
          Details
          {hasWarning && (
            <span title="Content type doesn't match the campaign objective.">
              <Icons.WarningIndicator customClass="text-warning-text inline-block ml-1 mb-1" />
            </span>
          )}
        </div>
      ),
      value: "Details",
    },
    { label: "Version history", value: "Version history" },
  ];
};
