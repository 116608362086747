import { useState } from "react";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import { Span } from "components/partials/typography/typography";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign } from "state/ducks/campaigns";
import Touchpoint from "models/touchpoint";

import { sortAlphabetically } from "utilities";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { TouchpointType, TouchpointTypeLabel } from "types/touchpoint";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

import Icons from "assets";

const LandingPageTable = () => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const [hasInvalidTouchpoints, setHasInvalidTouchpoints] = useState(false);

  const loadAllLandingPages = (
    options: PaginatedRequestOptions,
  ): Promise<PaginatedResponse<Touchpoint>> => {
    return Touchpoint.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      type: TouchpointType.LANDING_PAGE,
      options: { ...options, sort: "name" },
    }).then((results) => {
      results.items = sortAlphabetically(results.items);
      const anyInvalid = results.items.some((touchpoint) => !touchpoint.isValidContentType);
      setHasInvalidTouchpoints(anyInvalid);
      return results;
    });
  };

  const renderRow = (touchpoint: Touchpoint) => {
    return (
      <TableRow>
        <TableCell>
          {hasInvalidTouchpoints && !touchpoint.isValidContentType && (
            <span title="Content type doesn't match the campaign objective.">
              <Icons.WarningIndicator customClass="text-warning-text inline-block mr-2 -mt-2" />
            </span>
          )}
          {hasInvalidTouchpoints && touchpoint.isValidContentType && (
            <span className="invisible">
              <Icons.WarningIndicator customClass="text-warning-text inline-block mr-2 -mt-2" />
            </span>
          )}
          <RouterLink to={`../${touchpoint.id}/preview`}>{touchpoint.name}</RouterLink>
        </TableCell>
        <TableCell>
          <Span className="whitespace-nowrap">{touchpoint.url}</Span>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <PaginatedTable
      headers={[`${TouchpointTypeLabel.LP_TOUCHPOINT} name`, "URL"]}
      fetchPage={loadAllLandingPages}
      renderRow={renderRow}
    />
  );
};

export default LandingPageTable;
