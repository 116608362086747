import { StylesConfig, GroupBase } from "react-select";

import { ISelectFocusedStyles } from "./custom-select.types";

const getFocusedStyle = ({ isFocused, defaultColor, focusedColor }: ISelectFocusedStyles) =>
  isFocused ? focusedColor : defaultColor;

const middleSizeStyles = {
  control: {
    padding: "8px",
    borderRadius: "6px",
    height: "40px",
  },
  valueContainer: {
    gap: "8px",
  },
  multiValue: {
    height: "24px",
    borderRadius: "6px",
    padding: "0 4px 0 12px",
  },
  multiValueRemove: {
    margin: "2px 4px 0 8px",
  },
  singleValue: {
    fontSize: "16px",
  },
  placeholder: {
    fontSize: "16px",
  },
  option: {
    borderRadius: "6px",
    fontSize: "16px",
    height: "40px",
  },
};

export const getBasicSelectStyles = (
  isMiddle: boolean,
  isErrOccured: boolean,
): StylesConfig<any, boolean, GroupBase<any>> => {
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      minHeight: "auto",
      //custome styles come here
      transition: "border-color 0.2s ease-in",
      cursor: "pointer",
      height: "32px",
      padding: "0 6px",
      borderRadius: "6px",
      boxShadow: isErrOccured
        ? getFocusedStyle({
            isFocused,
            defaultColor: "transparent",
            focusedColor: "0px 0px 0px 2px #b71e2526",
          })
        : getFocusedStyle({
            isFocused,
            defaultColor: "none",
            focusedColor: "0px 0px 0px 2px #c6f5ed;",
          }),
      borderColor: isErrOccured
        ? "#b71e25"
        : getFocusedStyle({
            isFocused,
            defaultColor: "#e4e5e7",
            focusedColor: "#0d736a",
          }),
      ...(isMiddle && middleSizeStyles.control),

      "&:hover": {
        borderColor: isErrOccured ? "#b71e25" : "#0d736a",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      //custome styles come here
      gap: "8px",
      overflow: "unset",
      ...(isMiddle && middleSizeStyles.valueContainer),
    }),
    multiValue: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      margin: 0,
      display: "flex",
      alignItems: "center",
      minHeight: "auto",
      //custome styles come here
      height: "20px",
      borderRadius: "4px",
      padding: "0 4px 0 8px",
      backgroundColor: isDisabled ? "#f5f5f5" : "#fcfcfc",
      boxShadow: isFocused
        ? "0px 1px 1px 0px #00000024, 0px 2px 1px 0px #0000001f, 0px 1px 3px 0px #00000033"
        : "0px 1px 2px -1px #0000001a, 0px 1px 3px 0px #0000001a",
      ...(isMiddle && middleSizeStyles.multiValue),

      "&:hover": {
        boxShadow:
          "0px 1px 1px 0px #00000024, 0px 2px 1px 0px #0000001f, 0px 1px 3px 0px #00000033",
      },
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      //custome styles come here
      margin: "0 !important",
      padding: "0 !important",
    }),
    multiValueRemove: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      padding: 0,
      //custome styles come here
      margin: "1px 0 0 6px",
      backgroundColor: isFocused ? "#c6f5ed" : "transparent",
      color: isDisabled ? "#858b93" : "#0d736a",
      ...(isMiddle && middleSizeStyles.multiValueRemove),

      "&:hover": {
        color: "#0d736a",
        backgroundColor: "#c6f5ed",
        cursor: "pointer",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      //custome styles come here
      color: "inherit",
      overflow: "visible",
      fontSize: "14px",
      ...(isMiddle && middleSizeStyles.singleValue),
    }),
    placeholder: (styles) => ({
      ...styles,
      margin: 0,
      //custome styles come here
      fontSize: "14px",
      ...(isMiddle && middleSizeStyles.placeholder),
    }),
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      display: "flex",
      alignItems: "center",
      //custome styles come here
      cursor: "pointer",
      borderRadius: "6px",
      fontSize: "14px",
      height: "32px",
      color: isSelected ? "#fff" : "inherit",
      backgroundColor: isSelected
        ? "#0d736a"
        : getFocusedStyle({
            isFocused,
            defaultColor: "transparent",
            focusedColor: "#e9fbf8",
          }),
      ...(isMiddle && middleSizeStyles.option),

      "&:active": {
        ...styles[":active"],
        backgroundColor: "transparent",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      overflowY: "auto",
      //custome styles come here
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#e4e5e7",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#d4d4d8",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
    input: (styles) => ({
      ...styles,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    clearIndicator: () => ({
      display: "none",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: 0,
    }),
  };
};
