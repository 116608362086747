import { useState } from "react";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import Link from "components/partials/link/link";

import { useCurrentClient } from "state/ducks/clients";
import { useCurrentCampaign } from "state/ducks/campaigns";
import Touchpoint from "models/touchpoint";

import { baseIterableUrl, sortAlphabetically } from "utilities";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { TouchpointType } from "types/touchpoint";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

import Icons from "assets";

const EmailTable = () => {
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const [hasInvalidTouchpoints, setHasInvalidTouchpoints] = useState(false);

  const loadAllEmails = (
    options: PaginatedRequestOptions,
  ): Promise<PaginatedResponse<Touchpoint>> => {
    return Touchpoint.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      type: TouchpointType.EMAIL,
      options: { ...options, sort: "name" },
    }).then((results) => {
      results.items = sortAlphabetically(results.items);
      const anyInvalid = results.items.some((touchpoint) => !touchpoint.isValidContentType);
      setHasInvalidTouchpoints(anyInvalid);
      return results;
    });
  };

  const renderRow = (touchpoint: Touchpoint) => {
    return (
      <TableRow>
        <TableCell>
          {hasInvalidTouchpoints && !touchpoint.isValidContentType && (
            <span title="Content type doesn't match the campaign objective.">
              <Icons.WarningIndicator customClass="text-warning-text inline-block mr-2 -mt-2" />
            </span>
          )}
          {hasInvalidTouchpoints && touchpoint.isValidContentType && (
            <span className="invisible">
              <Icons.WarningIndicator customClass="text-warning-text inline-block mr-2 -mt-2 " />
            </span>
          )}
          <RouterLink to={`../${touchpoint.id}/preview`}>{touchpoint.name}</RouterLink>
        </TableCell>
        <TableCell>{touchpoint.subjectLine}</TableCell>
        <TableCell>
          <Link
            href={`${baseIterableUrl}${touchpoint.iterableTemplateId}`}
            target="blank"
            style={{ textDecoration: "underline" }}>
            {touchpoint.iterableTemplateId && `Iterable Email #${touchpoint.iterableTemplateId}`}
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <PaginatedTable
      headers={["Email", "Subject line", "Iterable Email"]}
      fetchPage={loadAllEmails}
      renderRow={renderRow}
    />
  );
};

export default EmailTable;
